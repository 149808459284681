body {
    font-family: 'Josefin Sans';
    font-size: 15px;
    font-weight: 400;
}
/* Style chung b-search */
[class*="b-search-"] {
    color: #666;
    font-size: 13px;
    max-width: 300px;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
}
[class*="b-search-"]>input {
    width: 100%;
    color: inherit;
    font-size: 1em;
    padding: 0 5px 0 7px;
    line-height: normal;
    border: none;
    background-color: transparent;
}
[class*="b-search-"]>input::-webkit-input-placeholder {
    color: #999;
}
[class*="b-search-"]>input::-moz-placeholder {
    color: #999;
}
[class*="b-search-"]>input:-moz-placeholder {
    color: #999;
}
[class*="b-search-"]>input:-ms-input-placeholder {
    color: #999;
}
[class*="b-search-"]>input:focus {
    outline: none;
}
[class*="b-search-"]>button {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    font-size: 1em;
    box-shadow: none;
    line-height: 0;
}
[class*="b-search-"]>button i {
    line-height: inherit;
}

/* BEGIN b-search-2 */
.b-search-2 {
    border: 1px solid #ddd;
}
.b-search-2>button {
    border-radius: 2px;
}
.b-search-2>button[class] {
    font-size: 0.924em;
    padding: 1em 0.584em 1em 0.584em;
    margin: 2px 2px 2px 0;
}
.b-search-2>button:not([class]) {
    color: inherit;
    padding: 1.077em 0.616em 1.077em 0.616em;
    background-color: transparent;
}

/* END b-search-2 */
/* Style chung s-header */
.hd-logo {
    line-height: 0;
}

.hd-menu h2 {
    font-size: inherit;
    font-weight: inherit;
}

a[ht-trigger="hd-menu"] {
    display: none;
    font-size: 14px;
    line-height: 1;
    padding: 0.5em;
    text-transform: uppercase;
    border: 1px solid;
}
a[ht-trigger="hd-menu"] i:first-child {
    float: left;
}
a[ht-trigger="hd-menu"] i:last-child {
    float: right;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
/* BEGIN s-header-15 */
.s-header-15 {
    width: 100%;
    background-color: rgb(255 255 255 / 73%);
    z-index: 111;
    position: fixed;
    top: 0;
    z-index: 999;
}
.s-header-15.hd-scroll {
    background-color: rgb(255 255 255 / 80%);
}
.s-header-15 .hd-logo {
    /* background-color: #fff; */
    margin-right: auto;
    transition: all 0.3s;
    padding: 10px 0;
}
.s-header-15.hd-scroll .hd-logo {
    padding: 15px 20px;
}
.s-header-15 .hd-logo>img {
    height: 65px;
    transition: all 0.3s;
}
.s-header-15.hd-scroll .hd-logo>img {
    height: 30px;
}
.s-header-15 .hd-menu {
    margin: 0 20px 0 auto;
    transition: all 0.3s;
}

.s-header-15 .hd-menu>ul>li {
    float: left;
    position: relative;
}
.s-header-15.hd-scroll .hd-menu>ul>li>a {
    color: #000;
}
.s-header-15.hd-scroll .hd-menu>ul>li.is-active>a, .s-header-15.hd-scroll .hd-menu>ul>li:not(.no-hover):hover>a {
    color: #b61218;
}
.s-header-15 .hd-menu>ul>li>a {
    display: block;
    line-height: 60px;
    padding: 0 22px;
    /* color: #fff; */
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}
.s-header-15 .hd-menu>ul>li>a:nth-last-child(2) h2:after {
    content: '\f0d7';
    font-family: FontAwesome;
    margin-left: 5px;
    float: right;
}
.s-header-15 .hd-menu>ul>li>ul {
    visibility: hidden;
    min-width: 200px;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    position: fixed;
}
.s-header-15 .hd-menu>ul>li>ul::after {
    content: '';
    height: 0;
    width: 0;
    border-bottom: 5px solid #fff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    top: -5px;
    left: 15px;
}
.s-header-15 .hd-menu>ul>li>ul>li a {
    display: block;
    padding: 5px 15px;
}
.s-header-15 .hd-menu>ul>li>ul>li a::before {
    content: '\f105';
    font-family: FontAwesome;
    font-size: 12px;
    margin-right: 6px;
    position: relative;
    top: -1px;
}
.s-header-15 .hd-menu>ul>li>ul>li:hover a {
    color: #b61218;
}
.s-header-15 .hd-menu>ul>li.is-active>a {
    color: #b61218;
}
.s-header-15 .hd-menu>ul>li:not(.no-hover):hover>a {
    color: #b61218;
}
.s-header-15 .hd-menu>ul>li:not(.no-hover):hover>ul {
    visibility: visible;
    position: absolute;
    top: 60px;
    left: 0;
    -webkit-animation: slide-up 0.5s;
    -o-animation: slide-up 0.5s;
    animation: slide-up 0.5s;
}
.s-header-15 .hd-search {
    position: relative;
}
.s-header-15 .hd-search>i {
    color: #b61218;
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    line-height: 1.924em;
    width: 1.924em;
    height: 1.924em;
}
.s-header-15 .hd-search>i:hover, .s-header-15 .hd-search>i.is-active {
    color: #fff;
    background-color: #b61218;
}
.s-header-15 .hd-search>form {
    display: none;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 44px;
    right: 0;
    -webkit-animation: slide-up 0.5s;
    -o-animation: slide-up 0.5s;
    animation: slide-up 0.5s;
}
.s-header-15 .hd-search>form.is-active {
    display: -webkit-flex;
    display: flex;
}
.s-header-15 .hd-language {
    line-height: 0;
    margin-left: 15px;
}

/* END s-header-15 */
/* BEGIN c-headline-11 */
.c-headline-11 {
    position: relative;
}
.c-headline-11 .c_name {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 25px;
    font-family: 'Anton';
    letter-spacing: 2px;
}
.c-headline-11 .c_name::before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b61218;
}
.c-headline-11 .c_name::after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 10px;
    left: 50%;
    margin-left: -50px;
    background-color: #b61218;
}
/* END c-headline-11 */
.b-card-77 {
    display: block;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: all 0.3s;
    margin-bottom: 30px;
    height: calc(100% - 30px);
}
.b-card-77 .b_sub {
    color: #b61218;
    margin-bottom: 10px;
}
.b-card-77 .b_title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 18px;
    transition: all 0.3s;
}
.b-card-77 .b_price {
    font-size: 20px;
    margin-bottom: 15px;
}
.b-card-77 .b_price span {
    font-weight: bold;
    color: #b61218;
}
.b-card-77 .ht-format-detail p {
    color: #666;
    margin-bottom: 12px;
}
.b-card-77 .ht-format-detail p::before {
    content: '\f00c ';
    font-family: FontAwesome;
    color: #b61218;
    margin-right: 5px;
}
.b-card-77 .b_more {
    font-size: 16px;
    transition: all 0.3s;
    margin-top: 25px;
    font-weight: 500;
}
.b-card-77 .b_more i {
    color: #b61218;
}
.b-card-77 .b_more:hover {
    color: #b61218;
}
.b-card-77:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.b-card-77:hover .b_title {
    color: #b61218;
}
/* BEGIN b-card-42 */
.b-card-42 {
    display: block;
    margin: 0 1.29px 30px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-align: center;
    height: calc(100% - 30px);
}
.b-card-42 .b_thumb {
    border-radius: 20px 20px 0 0;
}
.b-card-42 .b_thumb img {
    transition: all 0.6s ease-in-out;
}
.b-card-42:hover .b_thumb img {
    transform: scale(1.1);
}
.b-card-42 .b_title {
    font-size: 21px;
    margin: 5px 10px 10px;
    padding-bottom: 15px;
    -webkit-transition: 0.4s color ease-in-out;
    -o-transition: 0.4s color ease-in-out;
    transition: 0.4s color ease-in-out;
    font-weight: 500;
    color: #b61218;
}
.b-card-42:hover .b_title {
    color: #fe8514;
}
.b-card-42 .b_desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: #777;
    font-size: 15px;
}
.b-card-42 .b_date {
    margin: 15px 0 0;
}
.b-card-42 .b_date i {
    color: #fe8514;
}
.b-card-42 .b_top {
    position: relative;
}
/* END b-card-42 */
/* BEGIN c-animate-loopflicker */
.c-animate-loopflicker {
    -webkit-animation: loopflicker 1s infinite;
    -o-animation: loopflicker 1s infinite;
    animation: loopflicker 1s infinite;
}

@keyframes loopflicker {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
/* END c-animate-loopflicker */
/* BEGIN s-footer-8 */
.s-footer-8 {
    background: linear-gradient(to bottom, rgb(255 255 255 / 10%) 0%, rgb(199 199 199 / 70%) 150%), url(../images/bgs/bg-10.png) no-repeat;
    background-size: cover;
}
.s-footer-8 .s_top {
    padding: 30px 0;
    text-align: center;
}
.s-footer-8 .ft-info {
    display: block;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.s-footer-8 .ft-logo {
    text-align: center;
    margin: 30px 0;
}
.s-footer-8 .ft-logo img {
    height: 80px;
}
.s-footer-8 .ft-info>span {
    display: inline-block;
    padding: 0 10px;
    font-size: 20px;
}
.s-footer-8 .ft-info>span:not(:last-child) {
    border-right: 1px solid rgb(255 255 255);
}
.s-footer-8 .ft-info>span h1 {
    font-weight: bold;
    color: #b61218;
    font-size: 25px;
    font-family: 'Anton';
    letter-spacing: 2px;
}
.s-footer-8 .ft-contact {
    display: inline-block;
    padding-bottom: 10px;
}
.s-footer-8 .ft-contact>span {
    display: inline-block;
    padding: 0 10px;
    font-size: 20px;
}
.s-footer-8 .ft-contact>span i, .s-footer-8 .ft-info>span i {
    color: #b61218;
}
.s-footer-8 .ft-contact a:hover {
    color: #b61218;
}
.s-footer-8 .ft-social {
    line-height: 0;
    margin-top: 20px;
}
.s-footer-8 .ft-social a {
    font-size: 23px;
    /* background-color: transparent; */
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 22px;
}
.s-footer-8 .ft-social a i {
    color: #fff;
}
.s-footer-8 .s_bot {
    border-top: 1px solid #fff;
}
.s-footer-8 .ft-copyright {
    padding: 10px;
    font-size: 14px;
    color: #666;
}
.s-footer-8 .ft-phone {
    color: #fff;
    text-align: center;
    z-index: 1000;
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.s-footer-8 .ft-phone i {
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid;
    border-radius: 50%;
    background-color: #fe8514;
}
.s-footer-8 .ft-phone span {
    margin-left: 0;
    line-height: 1;
    border: 1px solid;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #fe8514;
    position: relative;
    left: -5px;
}
.s-footer-8 .ft-phone span:not(.text-hide) {
    padding: 5px;
}
.s-footer-8 .ft-backtop {
    opacity: 0;
    color: #fff;
    font-size: 20px;
    border: 1px solid;
    background-color: #fe8514;
    z-index: 1000;
    position: fixed;
    right: 10px;
    bottom: calc(60% - 1em);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.s-footer-8 .ft-backtop.is-show {
    opacity: 1;
    bottom: 15px;
}

/* END s-footer-8 */
/* BEGIN s-icon-2 */
.s-icon-2>a:first-child {
    opacity: 0;
    font-size: 20px;
    border: 1px solid #ddd;
    background-color: rgba(204, 204, 204, 0.5);
    z-index: 1000;
    position: fixed;
    right: 10px;
    bottom: calc(60% - 1em);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.s-icon-2>a:first-child.is-show {
    opacity: 1;
    bottom: 60px;
}
.s-icon-2>a:last-child {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1000;
    line-height: 0;
}
.s-icon-2>a:last-child span {
    font-size: 20px;
}
.s-icon-2>a:last-child span::before, .s-icon-2>a:last-child span::after {
    content: '';
    opacity: 0;
    width: calc(100% + 1.334em);
    height: calc(100% + 1.334em);
    margin: -0.666em;
    border-radius: 3px;
    background-color: #2658a1;
    border-radius: 3px;
    z-index: -1;
    position: absolute;
    -webkit-animation: ripple 1.5s infinite;
    animation: ripple 1.5s infinite;
}
.s-icon-2>a:last-child span::after {
    animation-delay: .3s !important;
    -webkit-animation-delay: .3s !important;
}
.s-icon-2>a:last-child span img {
    position: absolute;
}

@keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
/* END s-icon-2 */
.fb_dialog_content iframe {
    right: -6px !important;
    bottom: 6px !important;
}
/* END s-footer-7 */
.s-banner-1 {
    background-image: url(../images/slide-2.png);
    padding: 140px 75px 16px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
.s-banner-1::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 60%) 150%);
    position: absolute;
    top: 0;
    left: 0;
}
.s-banner-1 .s_content {
    text-align: center;
}
.s-banner-1 .s_content .title {
    color: #b61218;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-shadow: 1px 1px 1px #fff;
    position: relative;
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton';
    letter-spacing: 4px;
}
.s-banner-1 .s_content .title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 185px;
    height: 2px;
    background-color: rgba(255, 0, 0, 0);
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #ffffff 50%, rgba(255, 0, 0, 0));
}

/* BEGIN s-breadcrumb-1 */
.s-breadcrumb-1 {
    font-size: 14px;
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    z-index: 9;
    position: relative;
    color: #fff;
}
.s-breadcrumb-1 li:not(:first-child)::before {
    content: "\f101";
    font-family: FontAwesome;
    font-size: 0.786em;
    margin: 0 0.637em;
    color: #888;
}
.s-breadcrumb-1 li:first-child a>i {
    font-size: 1.429em;
}
.s-breadcrumb-1 li a {
    font-weight: 500;
}
.s-breadcrumb-1 li a:hover {
    color: #fe8514;
}

/* END s-breadcrumb-1 */
/* Style chung s-pagination */
[class*="s-pagination-"] {
    padding: 25px 0;
    font-size: 12px;
}
[class*="s-pagination-"]::after {
    content: '';
    display: table;
    clear: both;
}
[class*="s-pagination-"]>li {
    display: inline-block;
    margin: 0 2px;
    border: 1px solid #ddd;
    line-height: 2.334em;
    width: 2.5em;
    height: 2.5em;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
[class*="s-pagination-"]>li>a {
    display: block;
    color: #999;
    font-weight: bold;
    text-align: center;
}
[class*="s-pagination-"]>li:hover {
    background-color: #eee;
    color: #999;
}
[class*="s-pagination-"]>li.active {
    background-color: #b61218;
    border: 1px solid #b61218;
}
[class*="s-pagination-"]>li.active>a {
    color: #fff;
}

/* BEGIN s-pagination-1 */
.s-pagination-1>li {
    border-radius: 3px;
}

/* END s-pagination-1 */
/* BEGIN b-card-38 */
.b-card-38 {
    display: block;
    color: #fff;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
}
.b-card-38 .b_thumb img {
    transition: all 0.5s;
}
.b-card-38::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.b-card-38 .b_icon {
    width: 100%;
    font-size: 60px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    position: absolute;
    position: absolute;
    bottom: -100px;
    -webkit-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
}
.b-card-38:hover::before {
    background-color: rgba(0, 0, 0, 0.5);
}
.b-card-38:hover .b_icon {
    opacity: 1;
    visibility: visible;
    bottom: 35%;
}
.b-card-38:hover .b_thumb img {
    transform: scale(1.2) rotate(5deg);
}
/* END b-card-38 */
.s-icon-3 {
    display: block;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 65px;
    z-index: 1000;
    box-shadow: 0 -4px 8px 0 rgb(0 0 0 / 20%);
    border-top: 1px solid #eaeaea;
}
.s-icon-3 ul li {
    width: 20%;
    float: left;
    list-style: none;
    text-align: center;
    font-size: 13.5px;
}
.s-icon-3 ul li img {
    width: 30px;
    margin-top: 10px;
    margin-bottom: 0;
}
.s-icon-3 ul li span {
    display: block;
}
.s-icon-3 ul li.hotline img {
    width: 50px;
    margin-top: -12px;
}
@media screen and (min-width: 768px) {
    .s-icon-3 {
        display: none;
    }
}
@media screen and (min-width: 992px) {
    .s-header-15.hd-scroll .hd-menu>ul {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
    }
    .s-header-15.hd-scroll.is-active .hd-menu>ul {
        visibility: visible;
        opacity: 1;
    }
    .s-header-15 a[ht-trigger="hd-show"] {
        margin-left: auto;
        color: #b61218;
        font-size: 20px;
        order: 1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
    }
    .s-header-15.hd-scroll a[ht-trigger="hd-show"] {
        visibility: visible;
        opacity: 1;
    }
}
@media screen and (min-width: 1260px) {

    .s-footer-8 .ft-phone {
        display: none;
    }
}
@media screen and (max-width: 1259px) {
    .s-header-15 .hd-menu>ul>li>a {
        padding: 0 10px;
        font-size: 14px;
        color: #000;
    }
    .b-card-77 .b_thumb {
        margin-top: 30px;
    }
}
@media screen and (max-width: 991px) {
    .s-header-15 a[ht-trigger="hd-show"] {
        display: none;
    }
    .s-header-15 {
        padding: 5px 0;
    }
    .s-header-15 .hd-logo {
        margin-right: auto;
    }
    .s-header-15 .hd-logo>img {
        height: 40px;
    }
    .s-header-15 .hd-menu {
        order: 2;
        margin: 0 0 0 20px;
        position: relative;
    }
    .s-header-15 .hd-menu>a[ht-trigger="hd-menu"] {
        display: block;
        color: #b61218;
    }
    .s-header-15 .hd-menu>ul {
        visibility: hidden;
        width: 250px;
        background-color: rgba(255, 255, 255, 0.95);
        position: absolute;
        top: 40px;
        right: 0;
    }
    .s-header-15 .hd-menu>ul>li {
        float: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    .s-header-15 .hd-menu>ul>li>a {
        line-height: 40px;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
    .s-header-15 .hd-menu>ul>li:not(.no-hover):hover>ul {
        min-width: 100%;
        position: absolute;
        top: 40px;
        right: 0;
        left: auto;
    }
    .s-header-15 .hd-menu>ul>li:not(.no-hover):hover>ul::after {
        left: calc(100% - 24px);
    }
    .s-header-15 .hd-menu>ul.is-active {
        visibility: visible;
        -webkit-animation: slide-up 0.3s;
        -o-animation: slide-up 0.3s;
        animation: slide-up 0.3s;
    }
    .s-footer-8 .ft-info {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .s-footer-8 .ft-info>span:not(:last-child) {
        border-right: none;
    }
    .s-footer-8 .ft-info>span, .s-footer-8 .ft-contact>span {
        padding: 6px 10px;
    }
    .s-header-15 .hd-search>i {
        color: #b61218;
    }
    .s-banner-1 {
        padding: 120px 20px 15px;
    }
    .s-banner-1 .s_content .title {
        font-size: 35px;
    }
}
@media screen and (max-width: 767px) {
    .s-footer-8 .ft-phone, .fb_dialog_content, .s-icon-2>a:last-child {
        display: none;
    }
    .s-icon-2>a:first-child.is-show {
        bottom: 75px !important;
    }
    .s-footer-8 {
        margin-bottom: 65px;
    }
}
@media screen and (max-width: 479px) {
    .s-header-15 .hd-social {
        display: none;
    }
    .s-header-15 .hd-search {
        order: 1;
        margin: 0 -5px 0 15px;
    }
    .s-header-15 .hd-search>i {
        color: #fff;
        background-color: #b61218;
    }
    .s-header-15 .hd-search>form {
        top: 39px;
        width: 220px;
    }
    .s-footer-8 .ft-logo {
        margin: 30px 0 0;
    }
    .b-card-42 .b_title {
        font-size: 16px;
    }
    .b-card-42 .b_date {
        font-size: 13px;
    }
}

/* vietnamese */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3K8-C8QSw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3K9-C8QSw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3Kz-C8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}