.p-detail-69 .p_top {
    background-color: rgb(255 255 255 / 90%);
    padding: 10px 0;
    position: sticky;
    top: 61px;
    z-index: 9;
}
.p-detail-69 .p_top .title {
    font-weight: bold;
    color: #b61218;
}
.p-detail-69 .p_top ul li {
    display: inline-block;
    margin-left: 20px;
}
.p-detail-69 .p_top ul li a {
    font-weight: 500;
    padding: 3px 16px;
    border-radius: 20px;
    transition: all 0.3s;
}
.p-detail-69 .p_top ul li:hover a {
    background-color: #b61218;
    color: #fff;
}
.p-detail-69 .p_box {
    background-color: #f5f5f5;
    padding: 50px 0;
}
.p-detail-69 .p_content .sub {
    color: #b61218;
    margin-bottom: 10px;
    font-size: 25px;
}
.p-detail-69 .p_content .title {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 30px;
    transition: all 0.3s;
}
.p-detail-69 .p_content .price {
    font-size: 25px;
    margin-bottom: 20px;
}
.p-detail-69 .p_content .price span {
    font-weight: bold;
    color: #b61218;
}
.p-detail-69 .p_content .ht-format-detail p {
    color: #000;
    margin-bottom: 20px;
    font-size: 18px;
}
.p-detail-69 .p_content .ht-format-detail p::before {
    content: '\f00c ';
    font-family: FontAwesome;
    color: #b61218;
    margin-right: 5px;
}
.p-detail-69 .p_content .desc {
    font-weight: 300;
    font-size: 16px;
    margin: 30px 0;
    border-left: 5px solid #b61218;
    padding-left: 15px;
}
.p-detail-69 .p_detail {
    margin: 30px 0;
}
.p-detail-69 .p_detail .detail {
    margin-bottom: 30px;
}
.p-detail-69 .p_sidebar {
    border: 2px solid rgb(182 18 24 / 10%);
    border-radius: 10px;
    padding: 25px;
}
.p-detail-69 .p_sidebar .title {
    text-align: center;
    font-family: 'Anton';
    color: #b61218;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
.p-detail-69 .p_sidebar .ht-format-detail p {
    color: #000;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 300;
}
.p-detail-69 .p_sidebar .ht-format-detail p::before {
    content: '\f04d ';
    font-family: FontAwesome;
    color: #b61218;
    margin-right: 5px;
    font-size: 6px;
}
/* BEGIN c-button-8 */
.c-button-8 {
    display: inline-block;
    font-size: 17px;
    padding: 0.5em 1.372em 0.5em 1.372em;
    overflow: hidden;
    color: #fff;
    background-color: #b61218;
    border-radius: 20px;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: box-shadow 0.5s;
    -o-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
    margin-top: 15px;
}
.c-button-8>i.fa {
    padding-left: 5px;
}
.c-button-8::after {
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -140%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -140%, 0);
    -webkit-transition: transform 0.35s;
    -o-transition: transform 0.35s;
    transition: transform 0.35s;
}
.c-button-8:hover {
    box-shadow: 0 14px 26px -12px rgba(34, 34, 34, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 34, 34, 0.2);
}
.c-button-8:hover::after {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 140%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 140%, 0);
}

/* END c-button-8 */
/* BEGIN c-headline-13 */
.c-headline-13 .c_name {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: 15px;
    font-family: 'Anton';
    position: relative;
}
.c-headline-13 .c_name:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #111;
}
.c-headline-13 .c_name::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #333;
}
/* END c-headline-13 */
/* BEGIN c-form-border */
/* Copy when using c-form-border */
.c-form-border {
    border: 1px solid #ccc;
    border-radius: 20px;
    -webkit-transition: border-color 0.3s ease-in-out;
    -o-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
}
.c-form-border .c_input {
    padding: 1.215em 1.072em 1.215em 1.072em;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.c-form-border .c_input:focus {
    outline: none;
    border-color: #b61218;
}
.c-form-border--icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.c-form-border--icon .c_input {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 1%;
    z-index: 2;
}
.c-form-border--icon .c_icon {
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.c-form-border--icon .c_icon:first-child {
    padding: 0.5em 0 0.5em 1.072em;
}
.c-form-border--icon .c_icon:nth-child(2) {
    padding: 0.5em 1.072em 0.5em 0;
}
.c-form-border--icon .c_icon:not(.c_icon--top) {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.c-form-border--icon .c_icon--top>i {
    position: relative;
    top: -0.142em;
}
.c-form-border--label .c_label {
    color: #999;
    background-color: #fff;
    pointer-events: none;
    padding: 0 0.358em 0 0.358em;
    z-index: 9;
    position: absolute;
    top: 0.572em;
    left: 0.715em;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}
.c-form-border--label:not(.is-empty) .c_label, .c-form-border--label.is-focused .c_label {
    font-size: 0.786em;
    color: #b61218;
    position: absolute;
    top: -0.727em;
}
.c-form-border.is-focused {
    border-color: #b61218;
}
.c-form-border.is-error {
    margin-bottom: 1.786em;
    border-color: #f55145;
}
.c-form-border.is-error .c_label {
    color: #f55145 !important;
}
.c-form-border.is-error .c_icon+.c_error {
    left: 0.143em;
    text-align: left;
}
.c-form-border.is-error .c_icon+.c_error>span {
    left: 0;
    margin: 0;
}

/* END c-form-border */
.c-modal-1 .c-modal-box {
    max-width: 530px;
}
.c-modal-1 .c-modal-group {
    overflow: unset;
}
.c-modal-1 .c-modal-box .c_close {
    top: 0;
    right: -24px;
    color: #000;
    border-radius: 0 5px 5px;
    background-color: #fff;
}
.c-modal-1 .c-modal-box .c_close:hover {
    border-radius: 0 5px 5px;
    background-color: #fff;
    color: #b61218;
}
.c-modal-1 .c-modal-box .c_body h3 {
    text-align: center;
    margin-bottom: 10px;
    font-family: 'Anton';
    color: #b61218;
    font-size: 25px;
}
.c-modal-1 .c-modal-box .c_body button {
    border-radius: 20px;
    background-color: #b61218;
}
.c-modal-1 .c-modal-box .c_body .thumb {
    text-align: center;
    margin-top: 25px;
}
.c-modal-1 .c-modal-box .c_body .thumb img {
    height: 140px;
}
.c-modal-1 .c-modal-box .c_body .thumb h4 {
    margin-top: 10px;
    font-family: 'Anton';
    font-size: 18px;
}
@media (min-width: 992px) {
    .p-detail-69 .sticky {
        position: sticky;
        top: 115px;
    }
}
@media (min-width: 1260px) {
    .p-detail-69 .container {
        width: 1000px;
    }
    .pdl5 {
        padding-left: 5.38px;
    }
}
@media (max-width: 767px) {
    .p-detail-69 .p_box .row-flex {
        flex-direction: column-reverse;
    }
    .p-detail-69 .p_thumb {
        margin-bottom: 30px;
    }
    .p-detail-69 .p_top .title {
        display: none;
    }
}
@media (max-width: 575px) {
    .p-detail-69 .p_top ul li a {
        padding: 3px 6px;
        font-size: 13px;
    }
    .p-detail-69 .p_content .title {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .p-detail-69 .p_content .sub {
        font-size: 20px;
    }
    .p-detail-69 .p_content .ht-format-detail p {
        margin-bottom: 10px;
    }
    .p-detail-69 .p_content .desc {
        margin: 15px;
    }
    .c-button-8 {
        font-size: 15px;
    }
    .c-modal-1 .c-modal-box .c_close {
        right: 0;
    }
}
@media (max-width: 400px) {
    .p-detail-69 .p_top ul li {
        margin-left: 0;
    }
}