/* BEGIN p-detail-4 */
.p-detail-4 {
    background: rgb(0 0 0 / 3%);
    padding-bottom: 30px;
}
.p-detail-4 .p_title {
    font-size: 30px;
    line-height: 1.2em;
    margin-bottom: 0.6em;
    font-weight: 500;
    text-align: center;
    font-family: 'Anton';
    letter-spacing: 1px;
}
.p-detail-4 .p_nation {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
}
.p-detail-4 .p_nation i {
    color: #b61218;
}
.p-detail-4 .p_box {
    background-color: #fff;
    z-index: 99;
    position: relative;
    padding: 50px;
    transform: translateY(-30px);
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.p-detail-4 .p_desc {
    color: #b61218;
    font-size: 17px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: justify;
    border-radius: 5px;
    background-color: rgb(245 245 245);
    font-weight: 500;
    text-align: center;
}
.p-detail-4 .p_product {
    margin-top: 20px;
}
.p-detail-4 .p_product .c-headline-1 {
    margin-bottom: 25px;
}
.p-detail-4 .p_product .c-headline-1 .c_name {
    font-weight: bold;
    font-size: 20px;
}
.p-detail-4 .p_source {
    margin-bottom: 20px;
}
.p-detail-4 .p_source li {
    display: inline-block;
    margin-right: 20px;
    line-height: 0;
}
.p-detail-4 .p_source li span.fa::before {
    color: #fe8514;
}
.p-detail-4 .p_other>ul>li {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(228 228 228 / 50%);
    padding: 10px 0;
}
.p-detail-4 .p_other>ul>li a {
    display: block;
    transition: all .4s ease;
    font-weight: 500;
    font-size: 16px;
}
.p-detail-4 .p_other>ul>li::before {
    content: '\f105';
    font-family: FontAwesome;
    margin-right: 5px;
}
.p-detail-4 .p_other>ul>li::before {
    font-family: FontAwesome;
    margin-right: 5px;
    color: #b61218;
}
.p-detail-4 .p_other>ul>li>ul {
    margin-left: auto;
}
.p-detail-4 .p_other>ul>li>ul>li {
    font-size: 13px;
    color: #666;
}
.p-detail-4 .p_other>ul>li a:hover {
    color: #f60;
}
.p-detail-4 .p_other {
    margin-top: 30px;
}
.p-detail-4 .c-headline-12 {
    margin-bottom: 20px;
}
.p-detail-4 .c-headline-12 .c_name {
    border-left: 10px solid #b61218;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500;
}
/* END p-detail-4 */
.s-banner-1 {
    padding: 140px 75px 40px;
}
@media(max-width: 991px) {
    .p-detail-4.p_product .p_box {
        padding: 35px 40px 15px;
    }
    .p-detail-4 .p_box {
        padding: 35px 40px;
    }
    .p-detail-4 .b-card-42 {
        margin-bottom: 20px;
        height: calc(100% - 20px);
    }
    .p-detail-4 .p_title {
        font-size: 24px;

    }
    .p-detail-4 .p_other>ul>li>ul {
        display: none;
    }
}
@media(max-width: 767px) {
    .p-detail-4.p_product .p_box {
        padding: 35px 30px 15px;
    }
    .p-detail-4 .p_box {
        padding: 35px 30px;
    }
}
@media screen and (max-width: 575px) {
    .p-detail-4 .p_box {
        padding: 30px 20px;
    }
    .p-detail-4.p_product .p_box, .p-detail-4 .p_box {
        padding: 30px 15px;
    }
    .p-detail-4 .p_other>ul>li {
        margin-bottom: 5px;
    }
}
@media(max-width: 479px) {
    .p-detail-4.p_product .p_box, .p-detail-4 .p_box {
        padding: 30px 10px;
    }
    .p-detail-4.p_new .p_source li.date {
        display: none;
    }
    .p-detail-4 .p_title {
        font-size: 22px;
    }
}