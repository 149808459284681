.s-video-1 {
    position: relative;
}
.s-video-1::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 99%;
    background: url(../images/bgs/bg-9.png) repeat rgb(0 0 0 / 50%);
    background-size: 5px 1px;
    opacity: .2;
    z-index: 1;
}
.s-video-1 video {
    width: 100%;
    height: calc(100% - 200px);
}
.s-video-1 .s_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 80%;
}
.s-video-1 .s_caption .s_name {
    font-size: 70px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Anton';
    letter-spacing: 7px;
    margin: 5px 0 20px;
    text-shadow: 1px 1px 1px #000;
}
.s-video-1 .s_caption .s_sub {
    display: inline-block;
    font-size: 45px;
    color: #b61218;
    text-shadow: 1px 1px 1px #fff;
    font-family: Anton;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    padding: 0 60px;
    letter-spacing: 4px;
}
.s-video-1 .s_caption .s_sub:after,
.s-video-1 .s_caption .s_sub:before {
    position: absolute;
    content: "";
    width: 50px;
    height: 3px;
    top: 50%;
    transform: translateY(-50%);
    background: #f60;
}
.s-video-1 .s_caption .s_sub:before {
    left: 0;
}
.s-video-1 .s_caption .s_sub:after {
    right: 0;
}
/* BEGIN c-button-17 */
.c-button-17 {
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    z-index: 5;
    font-weight: 600;
}
.c-button-17:after {
    content: attr(data-text);
    padding: 10px 0;
    color: #fff;
    background-color: #b61218;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
}
.c-button-17 span {
    display: block;
    transition: all 0.3s;
    padding: 10px 25px;
}
.c-button-17:hover span {
    transform: translateY(-150%);
}
.c-button-17:hover::after {
    top: 0px;
}
/* BEGIN c-button-17 */
.s-about-1 {
    background: url('../images/bgs/bg-10.jpg');
    background-size: cover;
    padding: 0 0 30px;
}
.s-about-1 .s_box {
    width: 1000px;
    margin: 0 auto;
}
.s-about-1 .s_line {
    transform: translateY(-17px);
    width: 100%;
}
.s-about-1 .s_title {
    font-size: 35px;
    font-family: 'Anton';
    letter-spacing: 1px;
    color: #c5c5c5;
    margin: 30px 0 20px;
}
.s-about-1 .s_title span {
    color: #b61218;
}
.s-about-1 .s_title::after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    margin: 10px 0;
    background-color: #b61218;
}
.s-about-1 .ht-format-detail {
    font-size: 16px;
    /* font-weight: 300; */
    line-height: 26px;
    color: #666;
    margin-bottom: 45px;
}
.s-about-1 .s_why {
    margin-top: 30px;
}
.s-about-1 .s_why .c-headline-11 .c_name {
    color: #c5c5c5;
}
/* BEGIN b-card-3 */
.b-card-3 {
    display: block;
    margin-bottom: 30px;
}
.b-card-3::after {
    content: '';
    display: table;
    clear: both;
}
.b-card-3 .b_icon {
    float: left;
    width: 70px;
    margin-right: 15px;
    text-align: center;
}
.b-card-3 .b_icon>i {
    font-size: 90px;
    color: #d2d2d2;
}
.b-card-3 .b_content {
    float: left;
    margin-left: 23px;
}
.b-card-3 .b_title {
    font-size: 17px;
    margin-bottom: 4px;
    /* color: #b61218; */
}
.b-card-3 .b_desc {
    text-align: justify;
    font-size: 50px;
    font-family: 'Anton';
    color: #b61218;
}

/* END b-card-3 */
/* BEGIN b-card-37 */
.b-card-37 {
    text-align: center;
    padding: 20px 30px;
    background-color: rgb(0 0 0 / 3%);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    border-radius: 10px;
}
.b-card-37 .b_icon {
    font-size: 35px;
    color: #b61218;
    margin-bottom: 10px;
    -webkit-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
}
.b-card-37 .b_title {
    font-size: 20px;
    font-weight: 500;
    color: #b61218;
    margin-bottom: 20px;
    position: relative;
}
.b-card-37 .b_title:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: calc(50% - 25px);
    width: 50px;
    height: 1px;
    background: #d4d4d4;
}
.b-card-37 .b_desc {
    color: #666;
}
.b-card-37:hover .b_icon {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

/* BEGIN b-card-37 */
/* BEGIN s-product-1 */
.s-product-1 {
    margin: 40px 0 0;
}
.s-product-1 .s_title {
    text-align: center;
    font-size: 40px;
    font-family: 'Anton';
    letter-spacing: 2px;
}
.s-product-1 .b-card-77 {
    padding: 30px;
}
.s-product-1 .slick-slider {
    z-index: 1;
    display: flex;
}
.s-product-1 .slick-slider .slick-track, .s-product-1 .slick-slider .slick-list {
    display: flex;
}
.s-product-1 .slick-slider .slick-list {
    margin: 0 35px;
}
.s-product-1 .slick-slider .item {
    margin: 35px 20px;
    height: calc(100% - 50px);
    width: 100%;
}
/* END s-product-1 */
/* BEGIN b-card-9 */
.b-card-9 {
    padding: 15px 0 30px;
}
.b-card-9 .b_icon {
    font-size: 50px;
    text-align: center;
    color: #b61218;
}
.b-card-9 .b_desc {
    color: #000;
    font-size: 25px;
    font-style: italic;
    padding: 0 20px;
    text-align: center;
}
.b-card-9 .b_author {
    margin: 20px 0 15px;
    text-align: center;
}
.b-card-9 .b_author>span {
    display: block;
}
.b-card-9 .b_author>span:first-child {
    color: #b61218;
    font-size: 27px;
    text-transform: uppercase;
    font-family: 'Anton';
    letter-spacing: 1px;
}
.b-card-9 .b_author>span:last-child {
    color: #888;
    font-size: 20px;
    margin-top: 5px;
}
.b-card-9 .b_avatar {
    line-height: 0;
    position: relative;
    text-align: center;
}
.b-card-9 .b_avatar img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    border: 5px solid rgb(255 255 255 / 10%);
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}

/* END b-card-9 */
/* BEGIN s-home-5 */
.s-home-5 {
    background-image: url(../images/bgs/bg-14.jpg);
    background-size: cover;
    position: relative;
    padding: 40px 0 100px;
}
.s-home-5 .slick-list {
    margin: 0 50px;
}
.s-home-5 .slick-slide img {
    display: inline;
}

/* END s-home-5 */
/* BEGIN s-home-32 */
.s-home-32 .slick-list {
    margin: 0 20px;
}
.s-home-32 .s_box {
    transform: translateY(-60px);
    background-color: #fff;
}
.s-home-32 .slick-slider .slick-prev, .s-home-32 .slick-slider .slick-next {
    font-size: 8px;
}
.s-home-32 .item {
    margin: 0 15px;
    position: relative;
    overflow: hidden;
}
.s-home-32 .item::before {
    content: '';
    display: block;
    padding-bottom: 75%;
}
.s-home-32 .item>a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.s-home-32 .item>a>img {
    width: 100%;
}
.s-home-32 .item>a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.s-home-32 .item>a img {
    width: auto;
    max-height: 100%;
}

/* END s-home-32 */
@media screen and (max-width: 1259px) {
    .s-video-1 .s_caption .s_sub {
        font-size: 35px;
    }
    .s-video-1 .s_caption .s_name {
        font-size: 35px;
    }
    .s-about-1 .s_box {
        width: unset;
    }
}
@media screen and (max-width: 991px) {
    .s-about-1 .s_thumb {
        display: none;
    }
    .s-news-1 .row {
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 767px) {
    .s-video-1 .s_caption {
        display: none;
    }
    .s-product-1 .slick-slider .slick-list {
        margin: 0 15px;
    }
    .s-product-1 .slick-slider .slick-prev, .s-product-1 .slick-slider .slick-next {
        font-size: 10px;
    }
    .s-product-1 .slick-slider .item {
        margin: 15px;
    }
    .b-card-3 .b_icon {
        width: 35px;
    }
    .b-card-3 .b_icon>i {
        font-size: 50px;
    }
    .b-card-3 .b_desc {
        font-size: 40px;
    }
}
@media screen and (max-width: 575px) {
    .s-home-5 .slick-list {
        margin: 0 15px;
    }
    .s-home-5 .slick-slider .slick-prev, .s-home-5 .slick-slider .slick-next {
        font-size: 10px;
    }
    .b-card-3 .b_icon {
        width: 100%;
        margin-bottom: 10px;
    }
    .b-card-3 .b_icon>i {
        font-size: 60px;
    }
    .b-card-3 .b_content {
        text-align: center;
        float: none;
        margin-left: 0;
    }
    .b-card-3 .b_desc {
        text-align: center;
    }
    .b-card-9 .b_desc {
        font-size: 20px;
    }
    .b-card-9 .b_author>span:first-child {
        font-size: 22px;
    }
    .b-card-9 .b_author>span:last-child {
        color: #fff;
    }
}
@media screen and (max-width: 375px) {
    .s-product-1 .s_title {
        font-size: 30px;
    }
}