.s-about-2 {
    background: url('../images/bgs/bg-16.jpg');
    padding: 50px 0;
}
.s-about-2 .s_box {
    width: 900px;
    margin: 0 auto;
    text-align: center;
}
.s-about-2 .s_box:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.s-about-2 .s_sub {
    color: #b61218;
    font-weight: 500;
    font-size: 16px;
}
.s-about-2 .s_title {
    font-size: 40px;
    font-family: 'Anton';
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.s-about-2 .s_desc {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
}
.s-about-2 .ht-format-detail {
    text-align: justify;
}
.s-about-2 .s_button {
    margin-top: 30px;
}
/* BEGIN c-button-4 */
.c-button-4 {
    display: inline-block;
    font-size: 14px;
    line-height: 2.429em;
    padding: 0 20px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #000;
    border-radius: 20px;
}
.c-button-4 span {
    position: relative;
    left: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.c-button-4 span.fa::before {
    font-size: 0.929em;
    color: inherit;
}
.c-button-4:hover span {
    left: 5px;
}

/* END c-button-4 */
/* BEGIN b-card-37 */
.b-card-37 {
    text-align: center;
    padding: 20px 30px;
    background-color: rgb(0 0 0 / 3%);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    border-radius: 10px;
}
.b-card-37 .b_icon {
    font-size: 35px;
    color: #b61218;
    margin-bottom: 10px;
    -webkit-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
}
.b-card-37 .b_title {
    font-size: 20px;
    font-weight: 500;
    color: #b61218;
    margin-bottom: 20px;
    position: relative;
}
.b-card-37 .b_title:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: calc(50% - 25px);
    width: 50px;
    height: 1px;
    background: #d4d4d4;
}
.b-card-37 .b_desc {
    color: #666;
}
.b-card-37:hover .b_icon {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

/* BEGIN b-card-37 */
.s-why-1 {
    margin: 40px 0;
}

/* BEGIN s-home-5 */
.s-home-5 {
    background-color: #f4f1ec;
    padding: 50px 0;
}
.s-home-5 .s_thumb {
    padding: 11px;
    border: 5px solid #fff;
}
.s-home-5 .s_thumb img {
    padding: 11px;
}
.s-home-5 .s_thumb.ht-ratio--135::before {
    padding-bottom: calc(135% - 15px);
}
.s-home-5 .slick-list {
    margin: 0 50px;
}
.s-home-5 .slick-list .item {
    margin: 0 15px;
}
.s-home-5 .slick-slide img {
    display: inline;
}

/* END s-home-5 */
/* BEGIN b-card-38 */
.b-card-38 {
    display: block;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 20px;
}
.b-card-38::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.b-card-38 .b_icon {
    width: 100%;
    font-size: 30px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    position: absolute;
    position: absolute;
    bottom: 0;
    transform: translateY(-50%);
    -webkit-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
}
.b-card-38:hover::before {
    background-color: rgba(0, 0, 0, 0.5);
}
.b-card-38:hover .b_icon {
    opacity: 1;
    visibility: visible;
    bottom: 30%;

}

/* END b-card-38 */
.s-section-10 {
    margin: 60px 0 0;
}
.s-section-10 .item {
    margin: 0 15px;
}
@media screen and (max-width: 1259px) {
    .s-about-2 .s_box {
        width: unset;
    }
}
@media screen and (max-width: 575px) {
    .s-home-5 .slick-list {
        margin: 0 15px;
    }
    .s-home-5 .slick-slider .slick-prev, .s-home-5 .slick-slider .slick-next {
        font-size: 10px;
    }
    .s-section-10 .item {
        margin: 0 5px;
    }
    .s-section-10 {
        margin: 50px 0 0;
    }
}